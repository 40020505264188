import instance from "./BaseApi";

export function getProfile() {
  return instance.request({
    url: `auth/me/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getUsers() {
  return instance.request({
    url: `users-of-same-org/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptUpdateUser(params) {
  const data = {
    first_name: params.first_name,
    last_name: params.last_name,

  };
  return instance.request({
    url: `users/${params.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteUser(id) {
  return instance.request({
    url: `users/${id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptInviteUser(data) {
  return instance.request({
    url: `user/invite/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}
