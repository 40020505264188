import instance from "./BaseApi";

export function attemptGetOrgUsers() {
  return instance.request({
    url: `organisations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptInviteOrg(data) {
  return instance.request({
    url: `organisation/${data?.organisation}/invite/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}

export function attemptGetOrgDetails(id) {
  return instance.request({
    url: `organisations/${id}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptDeleteOrg(id) {
  return instance.request({
    url: `organisations/${id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrg(data) {
  return instance.request({
    url: `organisations/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptUpdateOrg(parmas) {
const data = {
  name: parmas?.name,
  email: parmas?.email,
  type: parmas?.type,
  status: parmas?.status,
}
  return instance.request({
    url: `organisations/${parmas?.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetOrgTypes() {
  return instance.request({
    url: `organization-types/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrgTypes(data) {
  return instance.request({
    url: `organization-types/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetOrgLocations(id) {
  return instance.request({
    url: `organisations/${id}/locations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptGetLocations(id) {
  return instance.request({
    url: `locations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrgLocations(data) {
  return instance.request({
    url: `locations/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteOrgLocations(data) {
  return instance.request({
    url: `locations/${data.id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptEditOrgLocations(data) {
  const editData = {
    "address": data.address,
    "name": data.name,
    "ap_grup": data.ap_group,
  }

  return instance.request({
    url: `locations/${data.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: editData,
  });
}

export function attemptOrgUsers(id) {
  return instance.request({
    url: `users/organisation/${id}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
