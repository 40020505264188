import axios from "axios";
import params from "../configs/params";
// import { attemptGetAccessTokenByRefreshToken } from "./AuthApi";

const baseApi = axios.create({
  baseURL: params.apiUrl,
  withCredentials: true,
});

baseApi.defaults.headers.common["Content-Type"] = "application/json";
baseApi.defaults.headers.common["ngrok-skip-browser-warning"] = "true";

baseApi.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// baseApi.interceptors.request.use(async (config) => {
//   //const expireAt = localStorage.getItem('expiresAt');
//   let token = localStorage.getItem('authToken');
//   //if (dayjs(expireAt).diff(dayjs()) < 1) {
//     const data = attemptGetAccessTokenByRefreshToken();
//     token = typeof data === 'string' ? data : await data();
//   //}
//   // setting updated token
//   localStorage.setItem('authToken', token);
//   return config;
// }, (err) => {
// });

export default baseApi;
