import React, { useState, useTransition } from "react";
import { Button, Logo, TextInput } from "components";
import { BodyMD, TitleXL } from "components/utils/Typography";
import useForm from "../../hooks/validation/useForm";
import { useNavigate, useLocation } from "react-router-dom";
import {
  validatePassword,
} from "../../components/utils/validationUtils";
import { useChangePassword } from "hooks/auth/useChangePassword";

export default function Changepassword() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
const [passwordChanged, setPasswordChanged] = useState(true)

const queryParams = new URLSearchParams(search);
const token = queryParams.get('token');
const uidb64 = queryParams.get('uidb64');

  const initialFormState = {
    password: "",
    confirmPassword: "",
    token: token,
    uidb64: uidb64
  };

  const {
    mutate: changePassword,
  } = useChangePassword();

 
 
  const validations = {
    password: (value) =>
      !value || !validatePassword(value)
        ? "Your password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters (e.g., !, @, #, $)."
        : "",
    confirmPassword: (value, formState) =>
      !value
        ? "Input a confirm password"
        : value !== formState.password
        ? "Passwords do not match"
        : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur } = useForm(
    initialFormState,
    validations
  );

 
  const handlePasswordSubmit = async (formState) => {
    const new_password = {
      new_password: formState.password,
      token: formState.token,
      uidb64: formState.uidb64
    }
    changePassword(new_password)
    setPasswordChanged(false)
    
  };


  
  const handleClick = () => {
    startTransition(() => {
      navigate('/login');
    });
  };

  return (
    <div className="flex flex-col max-w-lg mx-auto items-center justify-center content-center h-dvh">
      <div className="border-colors-border-natural-weak border rounded-lg w-[496px] ">
        <div className="flex justify-center gap-10 w-full rounded-t-lg px-12 py-10 bg-colors-bg-natural-strong">
          <Logo
            secondary
            icon="Logowifi"
            width="49px"
            height="40px"
            textSize="text-3xl"
          />
        </div>
        <div className="p-10 w-full bg-colors-bg-natural-surface rounded-b-lg">
          {passwordChanged ? (
            <form className="flex flex-col gap-10"  onSubmit={(e) => handleSubmit(e, handlePasswordSubmit)}>
              <div className="flex flex-col gap-6">
                <TextInput
                  type="password"
                  label="Password"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helptext={errors.password || "Your password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters (e.g., !, @, #, $)."}
                />

                <TextInput
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    value={formState.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.confirmPassword}
                    helptext={errors.confirmPassword || ""}
                />
              </div>

              <div className="flex flex-col gap-3">
                <Button type="submit" children="Reset Password" color="primary" />
              </div>
            </form>
          ) : (
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-4">
                <TitleXL className="text-center text-[28px] text-colors-brand-blue-dark">
                  Password Reset
                </TitleXL>
                <BodyMD className="text-center text-colors-brand-blue-dark-60">
                  Login to start using b connect.
                </BodyMD>
              </div>

              <Button
                type="button"
                children="Login"
                color="primary"
                onClick={handleClick}
                disabled={isPending}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
