import { useQuery } from '@tanstack/react-query';
import { attemptGetOrgTypes } from '../../api/OrgApi';

const fetchOrgTypes = async () => {
    return attemptGetOrgTypes().then((res) => res.data || [{ lebel : 'Select Org Type' , value: 0 }]);
};


export const useFatchOrgType = () => {
    return useQuery({ queryKey: ['org-type'], queryFn: fetchOrgTypes });
};