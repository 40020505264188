export const menuItems = [
    {
      label: "Dashboard",
      iconname: "Bpeople",
      to: "/dashboard",
    },
    {
      label: "Locations",
      iconname: "Location",
      to: "/locations",
    },
    {
      label: "Customers",
      iconname: "Bpeople",
      to: "/customers",
    },
    {
      label: "Account",
      iconname: "Account",
      to: "/account",
    },
  ];

  export const status = [
    { id: "active", name: "Active" },
    { id: "onboarding", name: "Onboarding" },
    { id: "deprecated", name: "Deprecated" },
  ];

  export const ap_group = [
    { id: "1", name: "Ap Group 1" },
    { id: "2", name: "Ap Group 2" },
    { id: "3", name: "Ap Group 3" },
    { id: "4", name: "Ap Group 4" },
  ];

  export const sortList = [
    { id: "1", label: "Newest First" },
    { id: "2", label: "Oldest First" },
    { id: "3", label: "Alphabetical (A-Z)" },
    { id: "4", label: "Alphabetical (Z-A)" },
  ];
