import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptDeleteOrgLocations } from '../../api/OrgApi';
import { toast } from 'react-toastify';

export const useDeleteOrgLocation= () => {
  const queryClient = useQueryClient()
    return useMutation({
      mutationKey: 'delete-org-location',
      mutationFn: (id) => attemptDeleteOrgLocations(id),
      onMutate:(id) => {},
      onSuccess: (id) => {
     toast.success("Location Deleted Successfully", {
      icon: false
     })
      },
      onError: (error) => {
        toast.error("Location Deleted Failed", {
          icon: false
         })
      },
      onSettled: async (_, error, variables) => {
        if (error) {
          //@TODO Handle error
        } else {
          await queryClient.invalidateQueries({
            queryKey: ["org-locations"],
          });
        }
      },
    });
  };