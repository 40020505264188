import { useQueryClient, useMutation } from "@tanstack/react-query";
import { attemptUpdateOrg } from "../../api/OrgApi";
import { toast } from 'react-toastify';

const useSyncMutation = (mutationFn, options) => {
  const mutationResults = useMutation(mutationFn, options);

  return {
    ...mutationResults,
    mutate: (...params) => {
      if (!mutationResults.isLoading) {
        mutationResults.mutate(...params);
      }
    }
  };
};

export const useUpdateOrg = () => {

  return useSyncMutation({
    mutationKey: ["update-org"],
    mutationFn: async (data) => attemptUpdateOrg(data),
    onSuccess: async (data) => {
      toast.success("Updated Successfully", {
        icon: false
       });
    },
    onError: (error) => {
      toast.error("Updated Failed", {
        icon: false
       })
    },
    onSettled: async (_, error) => {
      if (error) {
        //@TODO: Handle error
      } else {
        // await queryClient.invalidateQueries({ queryKey: ['profile'] });
      }
    }
  });
};