import { useMutation} from "@tanstack/react-query";
import { attemptChangeEmailRequest, attemptChangeEmailVerify } from '../../api/AuthApi';
import { toast } from 'react-toastify'; 

export const useChangeEmailRequest = () => {


  return useMutation({
    mutationKey: 'change-email',
    mutationFn: async (values) => {
      try {
        const res = await attemptChangeEmailRequest(values);
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: async (data) => {
     toast.success("Change Email request success")
    },
    onError: (error) => {
      toast.error("Change Email request success")
    },
    onSettled: () => {
      
    }
  });
};

export const useChangeEmailVerify = () => {


    return useMutation({
      mutationKey: 'change-email-verify',
      mutationFn: async (values) => {
        try {
          const res = await attemptChangeEmailVerify(values);
          return res.data;
        } catch (error) {
          console.error("Mutation Error:", error);
          throw error;
        }
      },
      onSuccess: async (data) => {
       toast.success("Change Email request success", {
        icon: false
       })
      },
      onError: (error) => {
        toast.error("Change Email request success", {
          icon: false
         })
      },
      onSettled: () => {
       
      }
    });
  };
