import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptSignIn } from "../../api/AuthApi";
import { getProfile } from "api/UsersApi";
import { useDispatch } from "react-redux";
import {
  setAuthTokens
} from "../../features/auth/authSlice";

export const useLogin = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: "login",
    mutationFn: async (values) => {
      try {
        const res = await attemptSignIn(values);
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: async (data) => {
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem(
        "access_token_expiration",
        data.access_token_expiration
      );
      localStorage.setItem(
        "refresh_token_expiration",
        data.refresh_token_expiration
      );

      dispatch(
        setAuthTokens({
          accessToken: data.access,
          refreshToken: data.refresh,
          accessTokenExpiration: data.access_token_expiration,
          refreshTokenExpiration: data.refresh_token_expiration,
        })
      );

      try {
        const profile = await getProfile();
        localStorage.setItem("user_type", profile.data.user_type);
        localStorage.setItem("user_id", profile.data.id);

        // Invalidate queries if the user_type is admin
        if (profile.user_type === "admin") {
          queryClient.invalidateQueries("adminMenuItems");
        }
      } catch (error) {}
    },
    onError: (error) => {
    },
    onSettled: () => {},
  });
};
