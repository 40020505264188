import { useQueryClient, useMutation } from "@tanstack/react-query";
import { attemptCreateOrg } from "../../api/OrgApi";
import { toast } from 'react-toastify';

export const useCreateOrg = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: "create-org",
    mutationFn: async (data) => attemptCreateOrg(data),
    onSuccess: async(data) => {
      toast.success("Organisation Created Successfully", {
        icon: false
       });
    },
    onError: (error) => {
      toast.error(`Organisation Creation Failed`, {
        icon: false
       });
    },
    onSettled: async(_, error) => {
      if (error) {
        //@TODO: Handle error
      } else {
        await queryClient.invalidateQueries({ queryKey: ['orgs'] });
      }
    }
  });
};
