/**
 * Logo Component
 * @description Renders the branding logo with customizable colors based on props.
 * @param {Object} props - The properties object.
 * @param {string} props.width - The width of the icon.
 * @param {string} props.height - The height of the icon.
 * @param {string} props.textSize - The textsize of the icon.
 * @param {boolean} props.primary - If true, use primary color scheme.
 * @param {boolean} props.secondary - If true, use secondary color scheme.
 * @param {boolean} props.default - If true, use default color scheme.
 * @param {string} props.icon - The name of the icon to display.
 * @returns {JSX.Element} The rendered logo component.
 */

import { Icon } from 'components/tokens/Icon'
import React from 'react'
import classNames from 'classnames';

export function Logo({...props}) {

    const { primary, secondary, default: isDefault, icon, width, height, textSize } = props;

 // Determine background color and text/icon color based on props
 let backgroundColorClass = '';
 let textColorClass = '';
 let iconColor = '';

 if (isDefault && primary) {
     backgroundColorClass = 'bg-colors-bg-natural-surface';
     textColorClass = 'text-colors-text-primary-strong';
     iconColor = 'blue';
 } else if (isDefault && secondary) {
     backgroundColorClass = 'bg-colors-bg-natural-surface';
     textColorClass = 'text-colors-text-natural-strong';
     iconColor = 'dark';
 } else if (primary) {
     backgroundColorClass = 'bg-colors-brand-blue';
     textColorClass = 'text-colors-text-primary-inverse';
     iconColor = 'white';
 } else if (secondary) {
     backgroundColorClass = 'bg-colors-bg-natural-strong';
     textColorClass = 'text-colors-text-natural-inverse';
     iconColor = 'white';
 }

    return (
        <div className={classNames('flex flex-row gap-4 h-12 px-4 py-3 items-center hover:cursor-pointer', backgroundColorClass)} {...props}>
            <div>
                <Icon name={icon} color={iconColor} width={width} height={height}/>
            </div>
            <div className={classNames('flex flex-row gap-1.5 items-center')}>
                <h1 className={classNames(textColorClass, textSize,'font-black font-azeret')}>b</h1>
                <h1 className={classNames(textColorClass, textSize,'font-black font-azeret')}>connect</h1>
            </div>
        </div>
    )
}
