import { useMutation } from "@tanstack/react-query";
import { attemptDeleteOrg } from '../../api/OrgApi';
import { toast } from 'react-toastify';

export const useDeleteOrg= () => {
    return useMutation({
      mutationKey: 'delete-org',
      mutationFn: (id) => attemptDeleteOrg(id),
      onMutate:(id) => {
      },
      onSuccess: (id) => {
        toast.success("Delete Successful", {
          icon: false
         });
      },
      onError: (error) => {
        toast.error("Delete Failed", {
          icon: false
         });
      },
     
    });
  };