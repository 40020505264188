import { Icon } from "components/tokens/Icon";
import { LabelSM } from "components/utils/Typography";
import React, { useState, useRef, useEffect } from "react";

export function DropdownMultiSelect({
  label,
  items,
  buttonClassName,
  menuClassName,
  itemClassName,
  iconClassName,
  icon,
  onSelect
}) {
  const [isOpen, setIsOpen] = useState(false);
const [selectedItems, setSelectedItems] = useState([]);
const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    const isSelected = selectedItems.includes(item);
    let newstate = []
    if (isSelected) {
      newstate = [...selectedItems.filter((i) => i !== item)];
    } else {
      newstate = [...selectedItems, item];
    }

    setSelectedItems(newstate);
    if (onSelect) {
      onSelect(newstate);
    }
  };

 


  const getSelectedItemsLabel = () => {
    if (selectedItems.length === 0) {
      return label;
    }
    if (selectedItems.length === items.length) {
      return "All Status";
    }
    return selectedItems.map((item) => item.name).join(", ");
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleToggle}
        id="dropdownHoverButton"
        data-dropdown-toggle="dropdownHover"
        data-dropdown-trigger="hover"
        className={`${buttonClassName} text-colors-text-natural-strong bg-colors-bg-natural-inverse hover:bg-colors-bg-natural-inverseHover focus:ring-4 focus:ring-offset-4 focus:outline-none focus:ring-colors-bg-primary-strong rounded-lg px-5 py-2.5 text-center inline-flex items-center`}
        type="button"
      >
        {icon ? (
          <div className="pr-2 w-10">
            <Icon width="12" height="12" color="dark" name={icon} />
          </div>
        ) : (
          ""
        )}
        <LabelSM className="truncate text-colors-bg-natural-strong">{getSelectedItemsLabel()}</LabelSM>
    
        <span className={`ml-3 ${iconClassName}`}>
          <Icon width="16" height="16" name="Caret" />
        </span>
      </button>

      <div
        id="dropdownHover"
        className={`z-10 mt-1 p-1 bg-colors-bg-natural-surface border border-colors-brand-blueDark-opacity_12 rounded-lg  w-full absolute truncate ${
          isOpen ? "block" : "hidden"
        } ${menuClassName}`}
      >
        <ul
          className=" text-sm text-gray-700"
          aria-labelledby="dropdownHoverButton"
        >
          {items.map((item, index) => (
            <li
              className={`flex flex-row gap-2 px-3 py-2 items-center rounded hover:cursor-pointer hover:bg-colors-bg-natural-surfaceHover truncate  `}
              key={item.id}
              onClick={() => handleItemClick(item)}
            >
              <input
                type="checkbox"
                checked={selectedItems.includes(item)}
                onChange={() => handleItemClick(item)}
                className=" rounded"
              />
              <div
                className={`block  ${itemClassName}`}
              >
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}





