import { Button, Logo, TextInput } from "components";
import { TitleXL, BodyMD } from "components/utils/Typography";
import React, { useEffect, useState } from "react";
import useForm from "../../hooks/validation/useForm";
import {
  validateEmail
} from "../../components/utils/validationUtils";
import { usePasswordReset } from "hooks";

export default function Resetpassword() {
  const [mailSent, setMailSent] = useState(true);

  const initialFormState = {
    email: "",
  };

  const {
    mutate: passwordReset, isSuccess: isSuccessMailSent,
  } = usePasswordReset();

  const validations = {
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
          ? "Invalid email address"
          : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur } = useForm(
    initialFormState,
    validations
  );

  const handleEmailSend = async (formState) => {
    passwordReset(formState);
    
  };

  useEffect(() => {
    if (isSuccessMailSent) {
      setMailSent(false)
    }
    
  }, [isSuccessMailSent]);


  return (
    <div className="flex flex-col max-w-lg mx-auto items-center justify-center content-center  h-dvh">
      <div className="border-colors-border-natural-weak border rounded-lg w-full bg bg-colors-bg-natural-surface">
        <div className="flex justify-center gap-10 w-full  rounded-t-lg px-12 py-10 bg-colors-bg-natural-strong">
          <Logo
            secondary
            icon="Logowifi"
            width="49px"
            height="40px"
            textSize="text-3xl"
          />
        </div>
        <div className="p-10 w-full gap-4">
          {mailSent ? (
            <form className="flex flex-col gap-10" onSubmit={(e) => handleSubmit(e, handleEmailSend)}>
              <div className="flex flex-col gap-6">
                <TextInput
                  type="text"
                  label="Email Address"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email}
                  helptext={errors.email || ""}
                />
              </div>

              <div className="flex flex-col gap-2">
                <Button
                  type="submit"
                  children="Send Password Reset Email"
                  color="primary"
                />
              </div>
            </form>
          ) : (
            <div className="flex flex-col gap-4">
              <TitleXL className="text-center text-[28px] text-colors-text-natural-strong ">
                Password Reset Email Sent
              </TitleXL>
              <BodyMD className="text-center text-colors-text-natural-medium">
                Open the email and follow the steps to reset your account
                password.
              </BodyMD>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
