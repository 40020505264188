import classNames from 'classnames';


/**
 * Renders a title in extra-large size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the title.
 * @return {JSX.Element} The rendered extra-large title.
 */
export function TitleXL({ className,  children }) {
    return <h1 className={classNames("text-title-xl", className)}>{children}</h1>;
}

/**
 * Renders a title in large size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the title.
 * @return {JSX.Element} The rendered large title.
 */
export function TitleLG({ className, children }) {
    return <h2 className={classNames("text-title-lg", className)}>{children}</h2>;
}

/**
 * Renders a title in medium size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the title.
 * @return {JSX.Element} The rendered medium title.
 */
export function TitleMD({ className, children }) {
    return <h3 className={classNames('text-title-md', className)}>{children}</h3>;
}

/**
 * Renders a title in small size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the title.
 * @return {JSX.Element} The rendered small title.
 */
export function TitleSM({ className, children }) {
    return <h4 className={classNames('text-title-sm', className)}>{children}</h4>;
}

/**
 * Renders a label in large size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the label.
 * @return {JSX.Element} The rendered large label.
 */
export function LabelLG({ className, children }) {
    return <h5 className={classNames("text-label-lg ", className)}>{children}</h5>;
}

/**
 * Renders a label in medium size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the label.
 * @return {JSX.Element} The rendered medium label.
 */
export function LabelMD({ className, children, ...props }) {
    return <label className={classNames("text-label-md", className)} {...props}>{children}</label>;
}

/**
 * Renders a label in small size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the label.
 * @return {JSX.Element} The rendered small label.
 */
export function LabelSM({ className, children }) {
    return <p className={classNames("text-label-sm ", className)}>{children}</p>;
}

/**
 * Renders a label in extra-small size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the label.
 * @return {JSX.Element} The rendered extra-small label.
 */
export function LabelXS({ className, children }) {
    return <p className={classNames("text-label-xs ", className)}>{children}</p>;
}

/**
 * Renders a body text in large size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the body text.
 * @return {JSX.Element} The rendered large body text.
 */
export function BodyLG({ className, children }) {
    return <p className={classNames("text-body-lg ", className)}>{children}</p>;
}

/**
 * Renders a body text in medium size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the body text.
 * @return {JSX.Element} The rendered medium body text.
 */
export function BodyMD({ className, children }) {
    return <p className={classNames("text-body-md ", className)}>{children}</p>;
}

/**
 * Renders a body text in small size.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - The additional class name for styling.
 * @return {JSX.Element} The rendered small body text.
 */
export function BodySM({ className, children }) {
    return <p className={classNames("text-body-sm ", className)}>{children}</p>;// <p className={"text-body-sm " + className}>{children}</p>;
}

/**
 * Renders a body text in extra-small size.
 *
 * @param {string} className - The additional class name for styling.
 * @param {string} children - The content of the body text.
 * @return {JSX.Element} The rendered extra-small body text.
 */
export function BodyXS({ className, children, ...props }) {
    return <p className={classNames("text-body-xs ", className)} {...props}>{children}</p>;// <p className={"text-body-xs " + className}>{children}</p>;
}

