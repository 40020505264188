import instance from "./BaseApi";

export function attemptSignIn(data) {
  return instance.request({
    url: `auth/token/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}

export function attemptGetAccessTokenByRefreshToken(data) {
  return instance.request({
      url: `auth/token/refresh/`,
      method: 'POST',
      withCredentials: false,
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      data : data
  });
}

export function attemptSignOut() {
  return instance.request({
    url: `auth/logout/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptPasswordReset(data) {
  return instance.request({
    url: `auth/password-reset/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data)
  });
}

export function attemptChangePassword(data) {
  return instance.request({
    url: `auth/password-reset-confirm/${data.uidb64}/${data.token}/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptRegistration(data) {
  return instance.request({
    url: `user/invite/accept/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}
export function attemptOrgRegistration(data) {
  return instance.request({
    url: `organisation/1/invite/accept/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptChangeEmailRequest(data) {
  return instance.request({
    url: `email-change/request/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptChangeEmailVerify(data) {
  return instance.request({
    url: `email-change/verify/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}