import React from 'react'
import {Card, Header, Icon } from 'components/';
import { BodySM, BodyXS, LabelMD, TitleXL } from 'components/utils/Typography'
import { useGetOrgLocations } from 'hooks';
import { ap_group } from 'mock';

export default function Location() {

  const id = localStorage.getItem('user_id')

  const  { data: locationList } = useGetOrgLocations(id);
  const getApGroupNameById = (id) =>
    ap_group.find((group) => group.id === id)?.name || null;
  return (
    <div className="w-[79%] mx-auto  ">
            <div className="flex flex-col w-full p-12 gap-12">
                <Header>
                    <div className="inline-block align-middle ">
                        <TitleXL className="inline-block align-middle leading-8 text-colors-text-natural-strong">
                            Locations
                        </TitleXL>
                    </div>
                    {/* <div className="flex flex-row gap-2">
                        <DropdownDatePicker
                            label="Today"
                        />
                        <Dropdown
                            label="All Locations"
                            items={[
                                { label: "All Locations", href: "#" },
                                { label: "Acme Camden", href: "#" },
                                { label: "Acme Brixton", href: "#" },
                                { label: "Acme Hackney", href: "#" },
                            ]}
                        />
                    </div> */}
                </Header>
                <div className="bg-colors-bg-natural-surface rounded-lg border border-colors-border-natural-weak ">
          {/* <div className="flex items-center justify-between p-6 rounded-t ">
            <div className="flex flex-col gap-2">
              <TitleLG>Customer Locations</TitleLG>
            </div>
          </div> */}
          <Card>
             {locationList && locationList.map((location) => 
             <div key={location.id} className="flex flex-row gap-10 py-4 px-6 items-center justify-between w-full rounded hover:bg-colors-bg-natural-inverseHover hover:cursor-pointer active:bg-colors-bg-natural-surfacePressed" >
                <div className="flex flex-row gap-10 items-center">
                  <Icon
                    color="gray"
                    width="23.97px"
                    height="24px"
                    name="Location"
                  />
                  <div className="flex flex-col gap-2">
                    <LabelMD className="text-colors-text-natural-strong font-medium">
                      {location?.name}
                    </LabelMD>
                    <BodyXS className="text-colors-text-natural-medium">
                      {location?.address}
                    </BodyXS>
                  </div>
                </div>

                <div className="flex flex-row items-center gap-10">
                  <BodySM className="text-left text-colors-text-natural-medium">
                  {getApGroupNameById(location?.ap_group)}
                  </BodySM>
                </div>
              </div>)}
            </Card>
        </div>
            </div>
        </div>
  )
}
