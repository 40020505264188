import { useState } from 'react';

const useForm = (initialState, validations) => {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validate = (name, value) => {
    if (validations[name]) {
      if (name === "confirmPassword") {
        return validations[name](value, formState);
      }
      return validations[name](value);
    }
    return '';
  };


 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    if (touched[name]) {
      setErrors({
        ...errors,
        [name]: validations[name](value, formState),
      });
    }
  };

  
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true,
    });
    setErrors({
      ...errors,
      [name]: validations[name](formState[name], formState),
    });
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    const validationErrors = {};
    Object.keys(validations).forEach((name) => {
      const error = validate(name, formState[name]);
      if (error) {
        validationErrors[name] = error;
      }
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      callback(formState);
    }
  };

  const resetForm = () => {
    setFormState(initialState);
    setErrors({});
  };

  return {
    formState,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    setErrors
  };
};

export default useForm;
