import React from 'react';
import { Cn } from 'components/utils/Cn';
import { LabelMD } from 'components/utils/Typography';
import { Icon } from 'components/tokens/Icon';
import { NavLink } from 'react-router-dom';
import './MenuItem.css';

const MenuItem = React.memo(({ label, iconname, width, height, to }) => {
    return (
        <NavLink
            to={to}
            className={({ isActive }) => 
                Cn('menu', isActive ? 'bg-colors-bg-natural-strong' : 'bg-colors-bg-natural-surface hover:bg-colors-bg-natural-surfaceHover')
            }
        >
            {({ isActive }) => (
                <div className='flex gap-3 items-center'>
                    <Icon
                        color={isActive ? 'white' : 'dark'}
                        width={width}
                        height={height}
                        name={iconname}
                    />
                    <LabelMD className={Cn('hover:cursor-pointer  ',isActive ? 'text-colors-text-natural-inverse font-medium' : 'text-colors-text-natural-strong font-medium')}>
                        {label}
                    </LabelMD>
                </div>
            )}
        </NavLink>
    );
});

export default MenuItem;
