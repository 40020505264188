import { useQuery } from "@tanstack/react-query";
import { attemptGetOrgUsers } from "../../api/OrgApi";
import { toast } from 'react-toastify'; 

const fetchOrgsData = async () => {
  return attemptGetOrgUsers().then((res) => res.data);
};

export const useFatchOrgs = () => {
  return useQuery({ queryKey: ["orgs"], queryFn: fetchOrgsData });
};
