import { useMutation } from "@tanstack/react-query";
import { attemptGetAccessTokenByRefreshToken } from "../../api/AuthApi";
import { useDispatch} from "react-redux";
import {
  setAuthTokens
} from "../../features/auth/authSlice";
export const useGetAccessToken = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: "get-access-token",
    mutationFn: async (data) => attemptGetAccessTokenByRefreshToken(data),
    onSuccess: async (data) => {
      localStorage.setItem("accessToken", data.data.access);
      localStorage.setItem("refreshToken", data.data.refresh);
      localStorage.setItem(
        "access_token_expiration",
        data.data.access_token_expiration
      );
      localStorage.setItem(
        "refresh_token_expiration",
        data.data.refresh_token_expiration
      );
      dispatch(
        setAuthTokens({
          accessToken: data.data.access,
          refreshToken: data.data.refresh,
          accessTokenExpiration: data.data.access_token_expiration,
          refreshTokenExpiration: data.data.refresh_token_expiration,
        })
      );
    },
  });
};
