import Sidebar from "layout/global/Sidebar";
import React from "react";
import { Outlet } from "react-router-dom";

export default function DashboardLayout() {
  return (
    <div className="flex h-screen divide-x divide-colors-border-natural-weak">
      <div className="fixed h-full">
        <Sidebar />
      </div>
      <div className="ml-72 flex-1 overflow-y-auto overflow-hidden">
        <Outlet />
      </div>
    
    </div>
  );
}
