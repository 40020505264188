import React from "react";
import {Dropdown, DropdownDatePicker, Header, Icon} from "components";
import {
  BodyXS,
  LabelSM,
  TitleLG,
  TitleSM,
  TitleXL,
} from "components/utils/Typography";
import { useGetOrgLocations } from "hooks";

export default function Dashboard() {

  const id = localStorage.getItem('user_id')

  const  { data: locationsData } = useGetOrgLocations(id);


  const initalItems = [
    { id: 0 , label: "All Locations" }
  ]

  if(locationsData && locationsData.length > 0){
    locationsData.map((location) => {
      initalItems.push({ id: location.id, label: location.name})
    })
  }

  return (
    <div className="w-[79%] mx-auto  ">
      <div className="flex flex-col w-full p-12 gap-12 ">
        <Header>
          <div className="inline-block align-middle ">
            <TitleXL className="inline-block align-middle leading-8 text-colors-text-natural-strong">
              Dashboard
            </TitleXL>
          </div>
          <div className="flex flex-row gap-2">
            <DropdownDatePicker label="Today" />
            <Dropdown
              label="All Locations"
              items={initalItems}
            />
          </div>
        </Header>
        <div className="flex flex-col gap-12 ">
          <div className="flex flex-row gap-12 justify-between">
            <div className="flex flex-col gap-4 p-6 rounded-lg bg-colors-bg-natural-surface border border-colors-border-natural-weak">
              <div className="flex flex-col">
                <TitleLG className="text-colors-text-natural-strong">68</TitleLG>
                <LabelSM className="text-colors-text-natural-strong">
                  Total Visitors
                </LabelSM>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Icon name="arrowup" />
                  <TitleSM className="text-colors-green">12%</TitleSM>
                </div>
                <BodyXS className="text-colors-text-natural-medium">
                  higher than last period
                </BodyXS>
              </div>
            </div>

            <div className="flex flex-col gap-4 p-6 rounded-lg bg-colors-bg-natural-surface border border-colors-border-natural-weak">
              <div className="flex flex-col">
                <TitleLG className="text-colors-text-natural-strong">64</TitleLG>
                <LabelSM className="text-colors-text-natural-strong">
                  Unique Visitors
                </LabelSM>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Icon name="arrowup" />
                  <TitleSM className="text-colors-green">12%</TitleSM>
                </div>
                <BodyXS className="text-colors-text-natural-medium">
                  higher than last period
                </BodyXS>
              </div>
            </div>

            <div className="flex flex-col gap-4 p-6 rounded-lg bg-colors-bg-natural-surface border border-colors-border-natural-weak">
              <div className="flex flex-col">
                <TitleLG className="text-colors-text-natural-strong">12</TitleLG>
                <LabelSM className="text-colors-text-natural-strong">
                  Returning Visitors
                </LabelSM>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Icon name="arrowup" />
                  <TitleSM className="text-colors-green">24%</TitleSM>
                </div>
                <BodyXS className="text-colors-text-natural-medium">
                  higher than last period
                </BodyXS>
              </div>
            </div>

            <div className="flex flex-col gap-4 p-6 rounded-lg bg-colors-bg-natural-surface border border-colors-border-natural-weak">
              <div className="flex flex-col">
                <TitleLG className="text-colors-text-natural-strong">50</TitleLG>
                <LabelSM className="text-colors-text-natural-strong">
                  New Visitors
                </LabelSM>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Icon name="arrowdown" />
                  <TitleSM className="text-colors-red">4%</TitleSM>
                </div>
                <BodyXS className="text-colors-text-natural-medium">
                  lower than last period
                </BodyXS>
              </div>
            </div>

            <div className="flex flex-col gap-4 p-6 rounded-lg bg-colors-bg-natural-surface border border-colors-border-natural-weak">
              <div className="flex flex-col">
                <TitleLG className="text-colors-text-natural-strong">
                  35 min
                </TitleLG>
                <LabelSM className="text-colors-text-natural-strong">
                  Avg. Time Spent
                </LabelSM>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Icon name="arrowup" />
                  <TitleSM className="text-colors-green">2%</TitleSM>
                </div>
                <BodyXS className="text-colors-text-natural-medium">
                  higher than last period
                </BodyXS>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak w-full border border-colors-border-natural-weak">
              <div className="flex items-center justify-between p-6 rounded-t ">
                <TitleLG className="text-colors-text-natural-strong">
                  Total Visitors
                </TitleLG>
              </div>

              <div className="flex flex-col gap-2 px-6 py-10 h-80">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row gap-6 justify-between"></div>
                </div>
              </div>
            </div>
            <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak w-full border border-colors-border-natural-weak">
              <div className="flex items-center justify-between p-6 rounded-t ">
                <TitleLG className="text-colors-text-natural-strong">
                  Total Returning vs New Visitors
                </TitleLG>
              </div>

              <div className="flex flex-col gap-2 px-6 py-10 h-80">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row gap-6 justify-between"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak w-full border border-colors-border-natural-weak">
              <div className="flex items-center justify-between p-6 rounded-t ">
                <TitleLG className="text-colors-text-natural-strong">
                  Total Visitors by Age
                </TitleLG>
              </div>

              <div className="flex flex-col gap-2 px-6 py-10 h-80">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row gap-6 justify-between"></div>
                </div>
              </div>
            </div>
            <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak w-full border border-colors-border-natural-weak">
              <div className="flex items-center justify-between p-6 rounded-t ">
                <TitleLG className="text-colors-text-natural-strong">
                  Total Visitors by Gender
                </TitleLG>
              </div>

              <div className="flex flex-col gap-2 px-6 py-10 h-80">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row gap-6 justify-between"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
