import { useQuery } from '@tanstack/react-query';
import { attemptGetOrgLocations } from '../../api/OrgApi';
import { toast } from 'react-toastify';

const fetchOrgLocations = async (id) => {
    return attemptGetOrgLocations(id).then((res) => res.data);
};


export const useGetOrgLocations = (id) => {
    return useQuery({ queryKey: ['org-locations'], queryFn: () => fetchOrgLocations(id) });
};