import React from "react";
import {Logo } from "components";
import MenuItem from "components/menuitem/MenuItem";
import { useMenuItems } from "hooks/validation/useMenuItem";
import { useNavigate } from "react-router-dom";


const Sidebar = () => {

  const navigate = useNavigate();

  const filteredMenuItems = useMenuItems();
  const userType = localStorage.getItem('user_type')

  const handelClick = () =>{
    if(userType === 'admin'){
      navigate(`/customers`)
    } else {
      navigate('/dashboard')
    }
  }

  return (
    <div className="w-72 h-svh bg-colors-bg-natural-surface">
      <div className="flex flex-col border-b border-colors-border-natural-weak">
        <div className="flex justify-center p-6 border-b border-colors-border-natural-weak" >
          <Logo
            default
            secondary
            icon="Logowifi"
            width="28.4px"
            height="24px"
            textSize="text-xxl"
            onClick = {() =>handelClick()}
          />
        </div>
        <div className="flex flex-col justify-center p-6 gap-3">
          {filteredMenuItems.map((item) => (
            <MenuItem
              key={item.label}
              label={item.label}
              iconname={item.iconname}
              width="16"
              height="16"
              to={item.to}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
