import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptEditOrgLocations } from '../../api/OrgApi';
import { toast } from 'react-toastify';

export const useEditOrgLocation= () => {
  const queryClient = useQueryClient()
    return useMutation({
      mutationKey: 'edit-org-location',
      mutationFn: (data) => attemptEditOrgLocations(data),
      onMutate:(data) => {
      },
      onSuccess: (data) => {
      toast.success("Location Updated Successfully", {
        icon: false
       })
      },
      onError: (error) => {
     toast.error("Location Updated Failed", {
      icon: false
     })
      },
      onSettled: async (_, error, variables) => {
        if (error) {
          //@TODO Handle error
        } else {
          await queryClient.invalidateQueries({
            queryKey: ["org-locations"],
          });
        }
      },
    });
  };