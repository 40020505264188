import { Icon } from "components/tokens/Icon";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Cn } from "components/utils/Cn";
import { LabelSM } from "components/utils/Typography";
export function Dropdown({
  label,
  items,
  buttonClassName,
  menuClassName,
  itemClassName,
  iconClassName,
  icon,
  onSelect,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleToggle}
        id="dropdownHoverButton"
        data-dropdown-toggle="dropdownHover"
        data-dropdown-trigger="hover"
        className={`${buttonClassName} text-colors-text-natural-strong bg-colors-bg-natural-inverse hover:bg-colors-bg-natural-inverseHover focus:ring-4 focus:ring-offset-4 focus:outline-none focus:ring-colors-bg-primary-strong rounded-lg  px-5 py-2.5 text-center inline-flex items-center`}
        type="button"
      >
        {icon ? (
          <div className="pr-2">
            <Icon width="12" height="12" color="dark" name={icon} />
          </div>
        ) : (
          ""
        )}

      <LabelSM className="truncate text-colors-bg-natural-strong">{selectedItem ? selectedItem.label : label}</LabelSM>  
        <span className={`ml-3 ${iconClassName}`}>
          <Icon width="16" height="16" name="Caret" />
        </span>
      </button>

      <div
        id="dropdownHover"
        className={`z-10 bg-colors-bg-natural-surface border border-colors-brand-blueDark-opacity_12 rounded-lg w-full absolute mt-1 ${isOpen ? "block" : "hidden"
          } ${menuClassName}`}
      >
        <ul
          className="p-2 text-sm  rounded "
          aria-labelledby="dropdownHoverButton"
        >
          {items.map((item, index) => (
            <li
              className={Cn('rounded', selectedItem === item || (!selectedItem && label === item.label)
                ? "bg-colors-bg-natural-strong text-colors-text-natural-inverse"
                : "")
                }
              key={index}
              onClick={() => handleItemClick(item)}
            >
              <div className="flex flex-row justify-between px-4 py-2 items-center rounded hover:cursor-pointer hover:bg-colors-bg-natural-surfaceHover ">
                <div
                  className={`block  truncate ${itemClassName} `}
                >
                  <LabelSM className={Cn( selectedItem === item || (!selectedItem && label === item.label)
                ? " text-colors-text-natural-inverse"
                : "text-colors-text-natural-strong")
                }>{item.label}</LabelSM>

                </div>
                {selectedItem === item || (!selectedItem && label === item.label) ? (
                  <Icon name="Check" width="12" height="12" color="white" />
                ) : (
                  ""
                )}
              </div>


            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  buttonClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
};
