import { Button, Logo } from 'components'
import { useNavigate } from 'react-router-dom';
import { TextInput } from 'components/textInput/TextInput'
import React, { useTransition } from 'react';
import useForm from "../../hooks/validation/useForm";
import {
  validateEmail
} from "../../components/utils/validationUtils";
import { useLogin } from 'hooks';

export default function Login() {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const userLogin = useLogin();
  
  const initialFormState = {
    email: "",
    password: "",
  };

  const validations = {
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        // ? "Input a password"
        // : !validatePassword(value)
        ? "Input a password"
        : "",
  };

 

  const { formState, errors, handleChange, handleSubmit, handleBlur,setErrors  } = useForm(
    initialFormState,
    validations
  );

  
  const handleLoginSubmit = async (formState) => {
    

    try {
      await userLogin.mutateAsync(formState); 

      const userType = localStorage.getItem('user_type');
      if (userType === 'admin') {
        navigate('/customers');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors({
          password: error.response.data.error
        });
      } else {
      }
    }
   
  };



 


  const handleClick = () => {
    startTransition(() => {
      navigate('/forgot-password');
    });
  };

  return (
    <div className='flex flex-col max-w-lg mx-auto items-center justify-center content-center  h-dvh '>
      <div className='border-colors-border-natural-weak border rounded-lg w-[496px] '>
        <div className='flex justify-center gap-10 w-full  rounded-t-lg px-12 py-10 bg-colors-bg-natural-strong '>
          <Logo secondary icon="Logowifi" width="49px" height="40px" textSize="text-3xl"/>
        </div>
        <div className='p-10 bg-colors-bg-natural-surface rounded-b-lg'>
          <form className='flex flex-col gap-10'  onSubmit={(e) => handleSubmit(e, handleLoginSubmit)}>
            <div className='flex flex-col gap-6'>
              <TextInput
                type="text"
                label="Email Address"
                name="email"
                value={formState.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.email}
                  helptext={errors.email || ""}
              />
              <TextInput
                type="password"
                label="Password"
                name="password"
                  value={formState.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helptext={errors.password || ""}
              />
            </div>

            <div className='flex flex-col gap-3'>
              <Button
                type="submit"
                children="Login"
                color="primary"
              />
              <Button
              type="button"
                onClick={handleClick}
                children="Forgotten Your Password?"
                color="default-secondary"
                disabled={isPending}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
