import { useQuery } from "@tanstack/react-query";
import {attemptOrgUsers} from "../../api/OrgApi";

const fetchOrgUsers = async (id) => {
    return attemptOrgUsers(id).then((res) => res.data);
};


export const useGetOrgUsers = (id) => {
    return useQuery({ queryKey: ['org-users'], queryFn: () => fetchOrgUsers(id) });
};