import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/tokens/Icon';
import { Datepicker } from 'components';
import {DateRangePicker} from 'components/datepicker/DateRangePicker';
import { LabelSM } from 'components/utils/Typography';

export function DropdownDatePicker({
  buttonClassName,
  menuClassName,
  itemClassName,
  iconClassName,
  icon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Today");
  const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
   const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
    
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date.startDate.format("DD MMMM") + " to " + date.endDate.format("DD MMMM"));
    setDateRange(date)
    // setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleToggle}
        id="dropdownHoverButton"
        data-dropdown-toggle="dropdownHover"
        data-dropdown-trigger="hover"
        className={`${buttonClassName}  bg-colors-bg-natural-surface hover:bg-colors-bg-natural-surfaceHover  focus:ring-4 focus:ring-offset-4 focus:outline-none focus:ring-colors-bg-primary-strong font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center`}
        type="button"
      >
        {icon ? (
          <div className="pr-2">
            <Icon width="12" height="12" color="dark" name={icon} />
          </div>
        ) : (
          ''
        )}
        <LabelSM className="text-colors-bg-natural-strong"> {selectedDate}</LabelSM>
       
        <span className={`ml-3 ${iconClassName}`}>
          <Icon width="16" height="16" name="Caret" />
        </span>
      </button>

      <div
        id="dropdownHover"
        className={`z-10 bg-colors-bg-natural-surface  rounded-lg shadow w-full absolute mt-1 ${isOpen ? 'block' : 'hidden'} ${menuClassName}`}
      >
        {isOpen && (
          <div className="text-gray-700 w-full">
            <DateRangePicker onDateSelect={handleDateSelect} dateRange={dateRange} />
          </div>
        )}
      </div>
    </div>
  );
}

DropdownDatePicker.propTypes = {
  buttonClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
};
