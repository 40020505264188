// import { useSelector } from 'react-redux';
import { menuItems } from "mock";

export const useMenuItems = () => {
  const userType = localStorage.getItem("user_type");

  return userType === 'admin'
    ? menuItems.filter(item => item.label === 'Account' || item.label === 'Customers')
    : menuItems.filter(item => item.label === 'Dashboard' || item.label === 'Locations' || item.label === 'Account');
};

