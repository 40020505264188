import React, { useState } from 'react';
import { Button } from "components/buttons/Button";
import { TextInput } from "components/textInput/TextInput";
import { BodyMD } from 'components/utils/Typography';
import {
  validateEmail,
} from "../../components/utils/validationUtils";

import { useSendInviteUser } from "hooks";

const EmailUpdateForm = ({ currentEmail, onClose, onSubmit }) => {
  const [newEmail, setNewEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({ email: "" });

  const validateInputs = () => {
    const newErrors = { email: "" };
    if (!newEmail.trim()) {
      newErrors.email = "Input your new email address. For example: john@email.com";
    } else if (!validateEmail(newEmail)) {
      newErrors.email = "Input your new email address. For example: john@email.com";
    }
    setErrors(newErrors);
    return !newErrors.email;
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    if (isSubmitted) {
      validateInputs();
    } else {
      setErrors({ email: "" });
    }
  };

  const handleBlur = () => {
    validateInputs();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (validateInputs()) {
      onSubmit(newEmail);
      setNewEmail("");
      setErrors({ email: "" });
    } else {
      //@TODO: Show error
    }
  };

  const handleClose = () => {
    setNewEmail("");
    setErrors({ email: "" });
    setIsSubmitted(false);
    onClose();
  };


  return (
    <form
    className="flex flex-col divide-y divide-colors-border-natural-weak"
    onSubmit={handleSubmit}
  >
    <div className="flex flex-col gap-6 p-6">
      <TextInput
        type="text"
        label="Current Email Address"
        name="currentEmail"
        value={currentEmail}
        disabled
      />
      <TextInput
        required
        type="text"
        label="New Email Address"
        name="newEmail"
        value={newEmail}
        onChange={handleEmailChange}
        onBlur={handleBlur}
        error={isSubmitted && errors.email}
        helptext={isSubmitted && errors.email}
      />
    </div>

    <div className="flex justify-end items-center p-6">
      <div className="flex flex-row gap-3 justify-end items-center">
        <Button
          type="button"
          small
          children="Cancel"
          color="default-primary"
          onClick={handleClose}
        />
        <Button
          type="submit"
          small
          children="Send Verification Email"
          color="primary"
        />
      </div>
    </div>
  </form>
  );
};


const VerificationCodeForm = ({ currentEmail, onClose, onResend, onSubmit }) => {

  const [verificationCode, setVerificationCode] = useState("");
  const [errors, setErrors] = useState({ verificationCode: "" });

  const validateVerificationCode = (code) => {
  
    const newErrors = { verificationCode: "" };
    if (!code.trim()) {
      newErrors.verificationCode = `Input the Verification Code sent to ${currentEmail}`;
    } 
  
    setErrors(newErrors);
    return !newErrors.verificationCode;
  };

  const handleVerificationCode = (e) => {
    const code = e.target.value
    validateVerificationCode(code);
    setVerificationCode(code);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateVerificationCode(verificationCode)) {
      onSubmit(verificationCode)
    }
  };

  const resendEmail= () =>{
    onResend( currentEmail)
  }

  const handleClose = () => {
    setVerificationCode("");
    setErrors({ verificationCode: "" });

    onClose();
  };
  

  return (
    <form
      className="flex flex-col divide-y divide-colors-border-natural-weak"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-6 p-6">
        <TextInput
          required
          type="text"
          label="Verification Code"
          name="verificationCode"
          value={verificationCode}
          onChange={handleVerificationCode}
          error={!!errors.verificationCode}
          helptext={
            errors.verificationCode ||
            `Input the Verification Code sent to ${currentEmail}`
          }
        />
      </div>
      <div className="flex flex-row justify-between items-center p-6">
        <Button
          type="button"
          small
          color="default-secondary"
          onClick={resendEmail}
        >
          Resend Verification Email
        </Button>
        <div className="flex flex-row gap-3">
          <Button
            type="button"
            small
            color="default-secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" small color="primary">
            Update Email Address
          </Button>
        </div>
      </div>
    </form>
  );
};




const InviteUserForm = ({ onClose, onSubmit }) => {
 
  const initialFormState = {
    email: "",
  };
  
  const [inviteEmail, setInviteEmail] = useState(initialFormState);
  const [error, setError] = useState({ email: "" });
  const inviteUser = useSendInviteUser();
 
  const validateInputs = (error) => {
    const newErrors = { email: "" };
    if (!inviteEmail.email.trim()) {
      newErrors.email = "Input your new email address. For example: john@email.com";
    } 
     if (!validateEmail(inviteEmail.email)) {
      newErrors.email = "Input your new email address. For example: john@email.com";
    } 
     if (error) {
      newErrors.email = error;
    }
    setError(newErrors);
    return !newErrors.email;
  };

  const handleBlur = () => {
    validateInputs();
  };

 const handleInputChange = (e) => {
    setInviteEmail({ ...inviteEmail, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInputs()) {
      try {
        await inviteUser.mutateAsync(inviteEmail); 
        onSubmit();
        setInviteEmail(initialFormState);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          validateInputs("An account already exists with this email address")
        } else {
        }
      }
    }
  };
  const handleClose = () => {
    setInviteEmail(initialFormState);
    setError({ email: "" });
    onClose();
  };
  
 

  return (
    <form className="flex flex-col divide-y divide-colors-border-natural-weak" onSubmit={ handleSubmit}>
      <div className="flex flex-col gap-6 p-6">
      <TextInput
      required
                type="text"
                label="Email Address"
                name="email"
                value={inviteEmail.email}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={!!error.email}
                  helptext={error.email || ""}
              />
      </div>
    
        <div className="flex flex-row justify-end items-center gap-3 p-6">
          <Button
            type="button"
            small
            color="default-primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            small
            color="primary"
          >
            Send Invite
          </Button>
        </div>
   
    </form>
  );
};

const ConfirmDelete = ({ user, onClose, onConfirm }) => {

    return (<div className="flex flex-col divide-y divide-colors-border-natural-weak">
    <div className="flex flex-col gap-6 p-6">
      <BodyMD>The user will no longer be able to access b connect. This action cannot be undone.</BodyMD>
    </div>
    <div className="flex justify-end items-center rounded-b gap-3 p-6">
        <Button
          type="button"
          small
          color="default-primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type="button"
          small
          color="critical"
          onClick={() => onConfirm(user)}
        >
          Delete
        </Button>
    </div>
  </div>)
};



export  { EmailUpdateForm, VerificationCodeForm, InviteUserForm, ConfirmDelete };
