import { Icon } from "components/tokens/Icon";
import { Cn } from "components/utils/Cn";
import { LabelLG, LabelMD } from "components/utils/Typography";

/**
* Button component.
* @param {Object} props - Component props.
* @param {React.ReactNode} props.children - Button content.
* @param {boolean} props.small - Indicates if the button is small.
* @param {string} props.iconName - Name of the icon to display.
* @param {string} props.iconColor - Color of the icon.
* @param {string} props.color - Color of the button.
* @param {string} props.iconPosition - Position of the icon ('left' or 'right').
* @returns {React.ReactNode} Button element.
*/

export function Button({ children, round, small, iconName, iconColor, color, iconPosition = 'left', ...props }) {
    return (
        <div className="flex flex-col gap-2">
            <button
                type="button"
                className={Cn(!round ? 'btn' : 'btn-round', !small ? 'btn-large' : 'btn-small', color)}
                {...props}
            >
                {iconName && iconPosition === 'left' && (
                    <Icon
                        color={iconColor || 'white'}
                        width={!small ? '16' : '12'}
                        height={!small ? '16' : '12'}
                        name={iconName}
                    />
                )}
                {!small ?<LabelLG className="">
                    {children}
                </LabelLG> : <LabelMD className="">
                    {children}
                </LabelMD> }
                {iconName && iconPosition === 'right' && (
                    <Icon
                        color={iconColor || 'white'}
                        width={!small ? '16' : '12'}
                        height={!small ? '16' : '12'}
                        name={iconName}
                    />
                )}
            </button>
        </div>
    );
}
