import { useMutation} from "@tanstack/react-query";
import { attemptRegistration, attemptOrgRegistration } from '../../api/AuthApi';
import { toast } from 'react-toastify'; 

export const useRegistration = () => {


  return useMutation({
    mutationKey: 'registration',
    mutationFn: async (values) => {
      try {
        const res = await attemptRegistration(values);
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: async (data) => {
     toast.success("Registration Success", {
      icon: false
     })
    },
    onError: (error) => {
      toast.error(`Registration Failed `, {
        icon: false
       })
    },
    onSettled: () => {

    }
  });
};

export const useOrgRegistration = () => {


  return useMutation({
    mutationKey: 'org-registration',
    mutationFn: async (values) => {
      try {
        const res = await attemptOrgRegistration(values);
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: async (data) => {
      toast.success("Registration Success")

    },
    onError: (error) => {
     toast.error(`Registration Failed`)
    },
    onSettled: () => {

    }
  });
};
