import {  useMutation } from "@tanstack/react-query";
import { attemptInviteOrg } from "../../api/OrgApi";
import { toast } from 'react-toastify';

export const useInviteOrg = () => {
  return useMutation({
    mutationKey: "invite-org",
    mutationFn: async (data) => {
      return attemptInviteOrg(data)
    },
    onSuccess: async (data) => {
      toast.success("Set up email has been sent", {
        icon: false
       });
    },
    onError: (error) => {
      toast.error("Invite Failed", {
        icon: false
       })
    },

  });
};