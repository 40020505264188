import React, { useState } from 'react';
import { Button } from "components/buttons/Button";
import { TextInput } from "components/textInput/TextInput";
import { SelectInput } from 'components/selectInput/SelectInput';
import { useInviteOrg } from 'hooks';
import { BodyMD } from 'components/utils/Typography';
import { ap_group } from 'mock';
import {
    validateEmail
} from "../../components/utils/validationUtils";

const CreateLocation = ({orgId, onClose, onSubmit }) => {
    const [formState, setFormState] = useState({ name: "", address: "", ap_group: "", organisation
    : orgId });

    const [errors, setErrors] = useState({ name: "", address: "", ap_group: "" });

    const validateForm = () => {
        const newErrors = {};

        if (!formState.name.trim()) {
            newErrors.name = "Location Name is required.";
        }
        if (!formState.address.trim()) {
            newErrors.address = "Address is required.";
        }
        if (formState.ap_group === "") {
            newErrors.ap_group = "Ap Group is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: name === 'ap_group' ? parseInt(value, 10) : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            onSubmit(formState);
            setFormState({ name: "", address: "", ap_group: "", organisation: orgId });
        }
    };

    const handleClose = () => {
        setFormState({ name: "", address: "", ap_group: "", organisation: orgId });
        setErrors({ name: "", address: "", ap_group: "" });
        onClose();
    };

    return (
        <form className="flex flex-col divide-y divide-colors-border-natural-weak" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-6 p-6">
                <div className="flex flex-row gap-6">
                    <TextInput
                        required
                        type="text"
                        name="name"
                        label="Location Name"
                        value={formState.name}
                        onChange={handleChange}
                        className="w-full"
                        error={errors.name}
                        helptext={errors.name || ""}
                    />
                    <TextInput
                        required
                        type="text"
                        name="address"
                        label="Address"
                        value={formState.address}
                        onChange={handleChange}
                        className="w-full"
                        error={errors.address}
                        helptext={errors.address || ""}
                    />
                </div>
                <div className="">
                    <SelectInput
                        label="AP Group"
                        name="ap_group"
                        options={ap_group}
                        placeholder="Select"
                        value={formState.ap_group}
                        onChange={handleChange}
                        error={!!errors.ap_group}
                        helpertext={errors.ap_group || ""}
                    />
                </div>
            </div>
            <div className="flex justify-end items-center p-6">
                <div className="flex flex-row gap-3 justify-end items-center">
                    <Button
                        type="button"
                        small
                        children="Cancel"
                        color="default-primary"
                        onClick={handleClose}
                    />
                    <Button
                        type="Create"
                        small
                        children="Create"
                        color="primary"
                    />
                </div>
            </div>
        </form>
    );
};


const EditLocation = ({ editLocationData, onClose, onSubmit, onDelete }) => {
    const initialData = {
        name: editLocationData?.name || "",
        address: editLocationData?.address || "",
        ap_group: editLocationData?.ap_group || "",
        id: editLocationData?.id || "",
        organisation: editLocationData?.organisation || "",
    };
    const [formState, setFormState] = useState(initialData);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formState.name.trim()) {
            newErrors.name = "Location Name is required.";
        }
        if (!formState.address.trim()) {
            newErrors.address = "Address is required.";
        }
        if (formState.ap_group === "") {
            newErrors.ap_group = "Establishment Type is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleClose = () => {
        setFormState(initialData);
        setErrors({});
        onClose();
    };
    const handleSubmit = (e) => {
        e.preventDefault();


        if (validateForm()) {
            onSubmit(formState);
        }
    };

    return (
        <form
            className="flex flex-col divide-y divide-colors-border-natural-weak"
            onSubmit={handleSubmit}
        >
            <div className="flex flex-col gap-6 p-6">
                <div className="flex flex-row gap-6">
                    <TextInput
                        required
                        type="text"
                        name="name"
                        label="Location Name"
                        value={formState.name}
                        onChange={handleChange}
                        className="w-full"
                        error={errors.name}
                        helptext={errors.name || ""}
                    />
                    <TextInput
                        required
                        type="text"
                        name="address"
                        label="Address"
                        value={formState.address}
                        onChange={handleChange}
                        className="w-full"
                        error={errors.address}
                        helptext={errors.address || ""}
                    />
                </div>
                <div>
                    <SelectInput
                        label="AP Group"
                        name="ap_group"
                        helperText=""
                        options={ap_group}
                        value={formState.ap_group}
                    onChange={handleChange}
                    error={!!errors.ap_group}
                        helpertext={errors.ap_group || ""}
                    />
                </div>
            </div>
            <div className="flex flex-row justify-between items-center p-6">
                <Button
                    type="button"
                    small
                    color="default-critical"
                onClick={onDelete}
                >
                    Delete Location
                </Button>
                <div className="flex flex-row gap-3">
                    <Button
                        type="button"
                        small
                        color="default-secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" small color="primary">
                        Save Changes
                    </Button>
                </div>
            </div>
        </form>
    )
};

const DeleteLocation = ({ orgId, onClose, onSubmit }) => {

    return <div className="flex flex-col divide-y divide-colors-border-natural-weak">
        <div className="flex flex-col gap-6 p-6">
            <BodyMD> This action cannot be undone.</BodyMD>
        </div>
        <div className="flex justify-end items-center rounded-b gap-3 p-6">
            <Button
                type="button"
                small
                color="default-secondary"
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                type="button"
                small
                color="critical"
                onClick={()=>onSubmit(orgId)}
            >
                Delete
            </Button>
        </div>
    </div>

}

const SetUpEmail = ({ orgId, onClose, onSubmit }) => {

    const initialFormState = {
        email: "",
        organisation: orgId,
    };

    const [setupEmail, setSetupEmail] = useState(initialFormState);
    const [error, setError] = useState({ email: "" });
    const inviteSetupEmail = useInviteOrg();

    const validateInputs = (error) => {
        const newErrors = { email: "" };
        if (!setupEmail.email.trim()) {
          newErrors.email = "Input your new email address. For example: john@email.com";
        } 
         if (!validateEmail(setupEmail.email)) {
          newErrors.email = "Invalid email address";
        } 
         if (error) {
          newErrors.email = error;
        }
        setError(newErrors);
        return !newErrors.email;
      };

      const handleBlur = () => {
        validateInputs();
      };
    
      const handleInputChange = (e) => {
        setSetupEmail({ ...setupEmail, [e.target.name]: e.target.value });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
          try {
            await inviteSetupEmail.mutateAsync(setupEmail); 
            onSubmit();
            setSetupEmail(initialFormState);
          } catch (error) {
            if (error.response && error.response.status === 400) {
              validateInputs("An account already exists with this email address")
            } else {
            }
          }
        }
      };

      const handleClose = () => {
        setSetupEmail(initialFormState);
        setError({ email: "" });
        onClose();
    };

    return (
        <form
            className="flex flex-col divide-y divide-colors-border-natural-weak"
            onSubmit={ handleSubmit}
        >
            <div className="flex flex-col gap-6 p-6">

                <TextInput
                    Required
                    type="text"
                    label="Email Address"
                    name="email"
                    value={setupEmail.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    error={!!error.email}
                    helptext={error.email || ""}
                />
            </div>

            <div className="flex justify-end items-center p-6">
                <div className="flex flex-row gap-3 justify-end items-center">
                    <Button
                        type="button"
                        small
                        children="Cancel"
                        color="default-primary"
                        onClick={handleClose}
                    />
                    <Button
                        type="submit"
                        small
                        children="Send"
                        color="primary"
                    />
                </div>
            </div>
        </form>
    );
};

const DeleteCustomer = ({ orgId, onClose, onSubmit }) => {

    return <div className="flex flex-col divide-y divide-colors-border-natural-weak">
        <div className="flex flex-col gap-6 p-6">
            <BodyMD> This action cannot be undone.</BodyMD>
        </div>
        <div className="flex justify-end items-center rounded-b gap-3 p-6">
            <Button
                type="button"
                small
                color="default-secondary"
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                type="button"
                small
                color="critical"
                onClick={()=>onSubmit(orgId)}
            >
                Delete
            </Button>
        </div>
    </div>

}

export { CreateLocation, SetUpEmail, EditLocation, DeleteLocation, DeleteCustomer };