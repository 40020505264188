import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   authTokens: {
//     accessToken: localStorage.getItem('accessToken'),
//     refreshToken: localStorage.getItem('refreshToken'),
//     accessTokenExpiration: localStorage.getItem('access_token_expiration'),
//     refreshTokenExpiration: localStorage.getItem('refresh_token_expiration'),
//   },
// };

const initialState = {
  authTokens: {
    accessToken: "",
    refreshToken: "",
    accessTokenExpiration: "",
    refreshTokenExpiration: "",
  },
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthTokens(state, action) {
      state.authTokens = action.payload;
    },
  
    setLogout(state) {
      state.authTokens = { accessToken: null, refreshToken: null, accessTokenExpiration: null, refreshTokenExpiration: null };
      localStorage.clear();
    },
  },
});

export const { setAuthTokens, setLogout } = authSlice.actions;

export default authSlice.reducer;
