import { Icon } from "components/tokens/Icon";
import dayjs from "dayjs";
import { Cn } from "components/utils/Cn";
import { generateDate, months } from "components/utils/Calendar";
import { useEffect, useState } from "react";
import { BodyXS } from "components/utils/Typography";

export function DateRangePicker({ onDateSelect, dateRange }) {
  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const currentDate = dayjs();
  const [today, setToday] = useState(currentDate);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hoverDate, setHoverDate] = useState(null);

useEffect(() => {
  setStartDate(dateRange.startDate)
  setEndDate(dateRange.endDate)
},[])
  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date.isBefore(startDate)) {
      setStartDate(date);
    } else {
      setEndDate(date);
      onDateSelect({ startDate, endDate: date });
    }
  };

  const isInRange = (date) => {
    if (startDate && !endDate && hoverDate) {
      return date.isAfter(startDate) && date.isBefore(hoverDate);
    }
    if (startDate && endDate) {
      return date.isAfter(startDate) && date.isBefore(endDate);
    }
    return false;
  };

  const getClassNames = (date, today, currentMonth) => {
    const classes = [
      "flex items-center justify-center w-8 h-8 hover:bg-colors-bg-natural-inverseHover hover:text-colors-text-natural-strong hover:cursor-pointer hover:rounded-lg",
    ];

    if (!currentMonth) {
      classes.push("text-colors-text-natural-medium");
    }

    if (today) {
      classes.push("bg-colors-bg-primary-strong text-colors-text-natural-inverse rounded-lg");
    }

    if (date.isSame(startDate)) {
      classes.push("bg-colors-bg-primary-strong text-colors-text-natural-inverse rounded-l-lg");
    }

    if (date.isSame(endDate) || (startDate && !endDate && date.isSame(hoverDate))) {
      classes.push("bg-colors-bg-primary-strong text-colors-text-natural-inverse rounded-r-lg");
    }

    if (isInRange(date) || (startDate && !endDate && date.isAfter(startDate) && hoverDate && date.isBefore(hoverDate))) {
      classes.push("bg-colors-bg-primary-strong text-colors-text-natural-inverse");
    }

    return Cn(...classes);
  };

  return (
    <div className="">
      <div className="flex w-full bg-colors-bg-natural-surface">
        <div className="flex flex-col items-center">
          <div className="flex bg-colors-bg-natural-surface  rounded-lg">
            <div className="flex flex-col">
              <div className="flex border border-colors-brand-blueDark-opacity_12 rounded-lg">
                <div className="flex flex-col p-2 w-60 bg-colors-bg-natural-surface rounded-lg">
                  <div className="flex items-center justify-between">
                    
                    <button
                      className="flex items-center justify-center p-2 rounded-lg hover:bg-colors-bg-natural-surfaceHover active:bg-colors-bg-natural-surfacePressed w-8 h-8 "
                      onClick={() => {
                        setToday(today.month(today.month() - 1));
                      }}
                    >
                      <Icon color="dark" width="12" height="12" name="Back" />
                    </button>
                    <div className="text-sm font-semibold">
                      {months[today.month()]}, {today.year()}
                    </div>
                    <button
                      className="flex items-center justify-center p-2 rounded-lg hover:bg-colors-bg-natural-surfaceHover active:bg-colors-bg-natural-surfacePressed w-8 h-8 hover:cursor-pointer"
                      onClick={() => {
                        setToday(today.month(today.month() + 1));
                      }}
                    >
                      <Icon color="dark" width="12" height="12" name="Forward" />
                    </button>

                  </div>
                  <div className="grid grid-cols-7 text-xs text-center text-colors-text-natural-strong pt-2">
                    {days.map((day, index) => (
                      <BodyXS key={index} className="flex items-center justify-center w-8 h-8 rounded-lg">
                        {day}
                      </BodyXS>
                    ))}

                    {generateDate(today.month(), today.year()).map(({ date, currentMonth, today }, index) => (
                      <BodyXS
                        key={index}
                        className={getClassNames(date, today, currentMonth)}
                        onClick={() => handleDateClick(date)}
                        onMouseEnter={() => setHoverDate(date)}
                        onMouseLeave={() => setHoverDate(null)}
                      >
                        {date.date()}
                      </BodyXS>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
