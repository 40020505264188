import { useMutation } from "@tanstack/react-query";
import { attemptPasswordReset } from '../../api/AuthApi';
import { toast } from 'react-toastify';

export const usePasswordReset = () => {
  return useMutation({
    mutationKey: 'password-reset',
    mutationFn: async (data) => attemptPasswordReset(data),
    onSuccess: (data) => {
      toast.success("Password reset success", {
       })
    },
    onError: (error) => {
      
    },
    onSettled: async (_, error) => {

    }
  });
};
