import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../api/UsersApi';
import { toast } from 'react-toastify'; 

const fetchUsersData = async () => {
    return getUsers().then((res) => res.data);
};

export const useFatchUsers = () => {
    return useQuery({ queryKey: ['users'], queryFn: fetchUsersData });
};