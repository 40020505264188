import { QueryClient, useMutation } from "@tanstack/react-query";
import { attemptDeleteUser } from '../../api/UsersApi';
import { toast } from 'react-toastify';

export const useDeleteUser= () => {
    return useMutation({
      mutationKey: 'delete-user',
      mutationFn: (id) => attemptDeleteUser(id),
      onMutate:(id) => {
      },
      onSuccess: (id) => {
        toast.success("Delete Successful", {
          icon: false
         });
      },
      onError: (error) => {
        toast.error("Delete Failed", {
          icon: false
         });
      },
      onSettled: async(_, error) => {
        if (error) {
          //@TODO Handle error
        } else {
          await QueryClient.invalidateQueries({ queryKey: ['users'] });
        }
      }
    });
  };