import { Button, Logo, TextInput } from "components";
import { BodyMD, TitleXL } from "components/utils/Typography";
import React, { useState, useTransition } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "../../hooks/validation/useForm";
import {
  validateEmail,
  validatePassword,
} from "../../components/utils/validationUtils";
import { useOrgRegistration } from "hooks";



export default function Setup() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isPending, startTransition] = useTransition();
  const [signup, setSignup] = useState(false);

  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const initialFormState = {
    first_name: "",
    last_name: "",
    email: email,
    password: "",
    confirmPassword: "",
    token: token
  };

  const registerUser =  useOrgRegistration()

  const validations = {
    firstName: (value) => (!value ? "Input your first name" : ""),
    lastName: (value) => (!value ? "Input your last name" : ""),
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        ? "Input a password"
        : !validatePassword(value)
        ? "Password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters."
        : "",
    confirmPassword: (value, formState) =>
      !value
        ? "Input a confirm password"
        : value !== formState.password
        ? "Passwords do not match"
        : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur } = useForm(
    initialFormState,
    validations
  );

  const handleSignupSubmit = async (formState) => {
    const signupData = { "first_name": formState.firstName , "last_name": formState.lastName, "email": formState.email, "password" : formState.password, "token" : formState.token };
    registerUser.mutate(signupData)
    setSignup(true);
  
   
  };

  const handleClick = () => {
    startTransition(() => {
      navigate("/login");
    });
  };

  return (
    <div className="flex flex-col max-w-lg mx-auto items-center justify-center h-dvh">
      <div className="border-colors-border-natural-weak border rounded-lg w-full bg-colors-bg-natural-surface">
        <div className="flex justify-center gap-10 w-full rounded-t-lg px-12 py-10 bg-colors-bg-natural-strong">
          <Logo
            secondary
            icon="Logowifi"
            width="49px"
            height="40px"
            textSize="text-3xl"
          />
        </div>
        <div className="p-10 w-full">
          {signup ? (
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-4">
                <TitleXL className="text-center text-colors-text-natural-strong">
                  Sign Up Complete
                </TitleXL>
                <BodyMD className="text-center text-colors-text-natural-medium">
                  Login to start using b connect.
                </BodyMD>
              </div>
              <Button
                type="button"
                children="Login"
                color="primary"
                onClick={handleClick}
                disabled={isPending}
              />
            </div>
          ) : (
            <form
              className="flex flex-col gap-10"
              onSubmit={(e) => handleSubmit(e, handleSignupSubmit)}
            >
              <div className="flex flex-row gap-6">
                <TextInput
                  required
                  type="text"
                  label="First Name"
                  name="firstName"
                  value={formState.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.firstName}
                  helptext={errors.firstName || ""}
                />
                <TextInput
                  required
                  type="text"
                  label="Last Name"
                  name="lastName"
                  value={formState.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.lastName}
                  helptext={errors.lastName || ""}
                />
              </div>
              <div className="flex flex-col gap-6">
                <TextInput
                  required
                  type="text"
                  label="Email Address"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email}
                  helptext={errors.email || ""}
                />
                <TextInput
                  required
                  type="password"
                  label="Password"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helptext={errors.password || ""}
                />
                <TextInput
                  required
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={formState.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.confirmPassword}
                  helptext={errors.confirmPassword || ""}
                />
              </div>
              <div className="flex flex-col gap-3">
                <Button type="submit" children="Sign Up" color="primary" />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
