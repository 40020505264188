import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, TextInput, Card } from "components";
import { setLogout } from '../../features/auth/authSlice';
import { useDispatch} from 'react-redux';
import moment from "moment";
import {
  BodyMD,
  BodySM,
  BodyXS,
  LabelMD,
  TitleLG,
} from "components/utils/Typography";
import {
  ConfirmDelete,
  EmailUpdateForm,
  InviteUserForm,
  VerificationCodeForm,
} from "./Modals";
import { useNavigate } from "react-router-dom";
import { useProfile, useFatchUsers, useDeleteUser } from "hooks";
import {
  useChangeEmailRequest,
  useChangeEmailVerify,
} from "hooks/auth/useChangeEmailRequest";
import { useUpdateUser } from "hooks/app/useUpdateUser";
import _debounce from "lodash/debounce";
// import { useAuth } from "components/common/AuthProvider";

export default function Account() {
  // const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);

  // call api with react query
  const { data: profile } = useProfile();
  const { data: usersData } = useFatchUsers();
  const deleteMutation = useDeleteUser();
  const emailRequest = useChangeEmailRequest();
  const verificationCodeVerify = useChangeEmailVerify();

  const initialData = {
    first_name: profile?.first_name || "",
    last_name: profile?.last_name || "",
    id: profile?.id || 0,
  };

  const [profileDetails, setProfileDetails] = useState(initialData);
  const [updatedProfileDetails, setUpdatedProfileDetails] =
    useState(initialData);
  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    if (profile) {
      setProfileDetails({ ...profile });
      setUpdatedProfileDetails({ ...profile });
    }
  }, [profile]);

  const handleDebounceMutateOrg = (payload) => {
    updateUserMutation.mutate(payload);
  };

  const debounceFn = useCallback(_debounce(handleDebounceMutateOrg, 1000), []);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const isEqual = Object.keys(updatedProfileDetails).every(
      (key) => updatedProfileDetails[key] === profileDetails[key]
    );
    if (!isEqual && value !== "") {
      setProfileDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      debounceFn({
        ...profileDetails,
        [name]: value,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur(e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEmailClick = (email) => {
    setModalTitle("Update Email");
    setCurrentAction("updateEmail");
    setIsModalOpen(false);
  };

  const handleSendVerificationEmail = (email) => {
    emailRequest.mutate({ new_email: email });
    setNewEmail(email);
    setShowVerificationForm(true);
    setModalTitle("Verification Code");
  };

  const resendEmail = (email) => {
    emailRequest.mutate({ new_email: email });
  };

  const handleVerificationCodeSubmit = (verificationCode) => {
    verificationCodeVerify.mutate({
      new_email: newEmail,
      otp: verificationCode,
    });
    setIsModalOpen(true);
    setShowVerificationForm(false);
  };

  const handleInviteUserClick = () => {
    setModalTitle("Invite User");
    setCurrentAction("inviteUser");
    setIsModalOpen(false);
  };

  const handleInviteUserSubmit = (inviteEmail) => {
    setIsModalOpen(true);
  };

  const handleDeleteUserClick = (user) => {
    setModalTitle("Delete User?");
    setUserToDelete(user);
    setCurrentAction("confirmDelete");
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async (id) => {
    deleteMutation.mutate(id);
    setIsModalOpen(true);
  };

  const renderModalContent = () => {
    switch (currentAction) {
      case "updateEmail":
        return showVerificationForm ? (
          <VerificationCodeForm
            currentEmail={profile.email}
            onClose={() => setIsModalOpen(true)}
            onResend={resendEmail}
            onSubmit={handleVerificationCodeSubmit}
          />
        ) : (
          <EmailUpdateForm
            currentEmail={profile?.email}
            onClose={() => setIsModalOpen(true)}
            onSubmit={handleSendVerificationEmail}
          />
        );

      case "inviteUser":
        return (
          <InviteUserForm
            onClose={() => setIsModalOpen(true)}
            onSubmit={handleInviteUserSubmit}
          />
        );
      case "confirmDelete":
        return (
          <ConfirmDelete
            user={userToDelete}
            onClose={() => setIsModalOpen(true)}
            onConfirm={handleConfirmDelete}
          />
        );

      default:
        return null;
    }
  };

  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.clear();
    navigate("/logout");
  };

  return (
    <div className=" ">
      <div className="flex flex-row w-full bg-colors-bg-natural-surface border-b py-6 px-10 border-colors-border-natural-weak justify-between items-center">
        <div className="flex flex-row">
          <TitleLG className="text-colors-text-natural-strong">Account</TitleLG>
        </div>
        <div className="flex flex-row ">
          <Button
            children="Contact Support"
            onClick={() => (window.location = "mailto:support@b-connect.co.uk")}
            small
            color="default-secondary"
          />
          <Button
            children="Log Out"
            small
            color="default-critical"
            iconName="LogOut"
            iconColor="red"
            iconPosition="left"
            onClick={handleLogout}
          />
        </div>
      </div>

      <div className="flex flex-col  p-12 gap-12 w-[70%] mx-auto">
        <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak border border-colors-border-natural-weak ">
          <div className="flex items-center justify-between p-6 rounded-t ">
            <TitleLG className="text-colors-text-natural-strong">
              Your Details
            </TitleLG>
          </div>

          <div className="flex flex-col gap-2 px-6 py-10">
            <div className="flex flex-col gap-6">
              <div className="flex flex-row gap-6 justify-between">
                <TextInput
                  type="text"
                  name="first_name"
                  label="First Name"
                  value={profileDetails.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  className="w-full"
                />

                <TextInput
                  type="text"
                  label="Last Name"
                  name="last_name"
                  value={profileDetails.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  className="w-full"
                />
              </div>
              <div className="flex flex-col gap-6">
                <TextInput
                  type="text"
                  label="Email Address"
                  name="email"
                  value={profile?.email}
                  onClick={handleEmailClick}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-colors-bg-natural-surface rounded-lg  divide-y divide-colors-border-natural-weak border border-colors-border-natural-weak ">
          <div className="flex items-center justify-between p-6 rounded-t  ">
            <div className="flex flex-col gap-2">
              <TitleLG className="text-colors-text-natural-strong">
                Users
              </TitleLG>
              <BodyMD className="text-colors-text-natural-medium">
                {profile?.organisation.name}
              </BodyMD>
            </div>
            <Button
              children="Invite User"
              small
              color="default-primary"
              iconName="Subtract"
              iconColor="blue"
              iconPosition="right"
              onClick={handleInviteUserClick}
            />
          </div>
          <Card>
            <div className="flex flex-row gap-10 py-4 px-6">
              <div className="flex flex-col gap-2">
                <LabelMD className="text-colors-text-natural-strong font-medium">
                  {profile?.first_name} {profile?.last_name} (You)
                </LabelMD>
                <BodyXS className="text-colors-text-natural-medium">
                  {profile?.email}
                </BodyXS>
              </div>
              <div className=""></div>
            </div>

            {usersData &&
              usersData
                .filter((item) => item?.email !== profile?.email)
                .map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-10 py-4 px-6 items-center justify-between w-full"
                  >
                    <div className="flex flex-col gap-2">
                      <LabelMD className="text-colors-text-natural-strong font-medium">
                        {item?.first_name} {item?.last_name}
                      </LabelMD>
                      <BodyXS className="text-colors-text-natural-medium">
                        {item?.email}
                      </BodyXS>
                    </div>
                    <div className="flex flex-row items-center gap-10">
                      <BodySM className="text-left text-colors-text-natural-medium">
                        {item?.last_activity
                          ? `Last active ${moment(
                              item?.last_activity
                            ).fromNow()}`
                          : "Last active 1 hour ago"}
                      </BodySM>
                      <Button
                        children="Delete"
                        small
                        color="default-critical"
                        onClick={() => handleDeleteUserClick(item.id)}
                      />
                    </div>
                  </div>
                ))}
          </Card>
        </div>

        <Modal
          title={modalTitle}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(true)}
        >
          {renderModalContent()}
        </Modal>
      </div>
    </div>
  );
}
