import { useQuery } from '@tanstack/react-query';
import { getProfile } from '../../api/UsersApi';
import { toast } from 'react-toastify';

const fetchProfileData = async () => {
    return getProfile().then((res) => res.data);
};


export const useProfile = () => {
    return useQuery({ queryKey: ['profile'], queryFn: fetchProfileData });
};
