import {Logo, Button} from 'components';
import { TitleXL } from 'components/utils/Typography';
import React, { useTransition } from "react";
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();

    const handleClick = () => {
        startTransition(() => {
          navigate('/login');
        });
      };


  return (
    <div className="flex flex-col max-w-lg mx-auto items-center justify-center content-center h-dvh">
      <div className="border-colors-border-natural-weak border rounded-lg w-full bg-colors-bg-natural-surface">
        <div className="flex justify-center gap-10 w-full rounded-t-lg px-12 py-10 bg-colors-bg-natural-strong">
          <Logo
            secondary
            icon="Logowifi"
            width="49px"
            height="40px"
            textSize="text-3xl"
          />
        </div>
        <div className="p-10 w-full">
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-4">
                <TitleXL className="text-center text-[28px] text-colors-text-natural-strong">
                You have been logged out.
                </TitleXL>
              </div>

              <Button
                type="button"
                children="Login"
                color="primary"
                onClick={handleClick}
                disabled={isPending}
              />
            </div>
        
        </div>
      </div>
    </div>
  )
}
