import { useMutation } from "@tanstack/react-query";
import { attemptChangePassword } from '../../api/AuthApi';
import { toast } from 'react-toastify';

export const useChangePassword = () => {
  return useMutation({
    mutationKey: 'change-password',
    mutationFn: async (data) => attemptChangePassword(data),
    onSuccess: (data) => {
      toast.success("Password change success", {
        icon: false
       })
    },
    onError: (error) => {
      toast.error("Password change failed", {
        icon: false
       })
    },
    onSettled: async (_, error) => {

    }
  });
};