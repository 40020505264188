import React from "react";
import { TitleLG } from "components/utils/Typography";
import { Cn } from "components/utils/Cn";



export function Modal({ children, title, footer, onClose, isOpen }) {
  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className={Cn('overflow-y-hidden  overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-colors-brand-blueDark-opacity_40', isOpen ? 'hidden' : '')}
    >
      <div className="relative p-4 w-full max-w-[800px]  flex flex-col mx-auto items-center justify-center content-center h-svh">
        <div className="relative bg-colors-bg-natural-surface rounded-lg shadow divide-y divide-colors-border-natural-weak w-full">
          <div className="flex items-center justify-between p-6 rounded-t">
            <TitleLG className="text-colors-text-natural-strong">{title}</TitleLG>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
