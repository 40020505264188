import { useQuery } from '@tanstack/react-query';
import { attemptGetOrgDetails } from '../../api/OrgApi';


const fetchOrgDetails = async (id) => {
    return attemptGetOrgDetails(id).then((res) => res.data);
};


export const useFatchOrgDetails = (id) => {
    return useQuery({ queryKey: ['org-details'], queryFn: () => fetchOrgDetails(id)});
};