import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const user_type = localStorage.getItem("user_type");
  if (accessToken || refreshToken || user_type) {
    return {
      auth: true,
      role: user_type
    };
  } else {
    return {
      auth: false,
      role: null
    };
  }
};

const PublicRoute = () => {
  const { auth, role } = useAuth();

  if (auth) {
    return <Navigate to={role === "admin" ? "/customers" : "/dashboard"} />;
  }

  return <Outlet />;
};

export default PublicRoute;
