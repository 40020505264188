import React from "react";
import { LabelXS, LabelMD } from "components/utils/Typography";
import { Cn } from "components/utils/Cn";
import { useId } from "react";
import "./SelectInput.css";

export function SelectInput({
  label = "",
  helpertext = "",
  options = [],
  error = false,
  ...props
}) {
  const htmlId = useId();

  return (
    <div className="flex flex-col gap-2 rounded-lg w-full relative">
      <LabelMD
        htmlFor={htmlId}
        className={
          error
            ? "text-colors-text-critical-strong"
            : "text-colors-text-natural-strong"
        }
      >
        {label}
      </LabelMD>
      <select
        id={htmlId}
        className={Cn("input-select text-body-md", error ? "red" : "")}
        {...props}
      >
        <option value="" disabled>
          {props.placeholder}
        </option>
        {options &&
          options?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
      {helpertext && (
        <LabelXS
          className={
            error ? `text-colors-text-critical-strong absolute bottom-[-20px]` : "text-colors-text-natural-medium"
          }
        >
          {helpertext}
        </LabelXS>
      )}
    </div>
  );
}
